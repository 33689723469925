<template>
  <div class="animated fadeIn">
  Page not found
<!--
    <b-button variant="primary" :active="true" size="lg" @click="addNew()">New Category</b-button>
    <b-card style="top: 10px">
      <table class="table table-striped">
        <thead>
          <th>ID</th>
          <th>Name</th>
          <th>Discription</th>
          <th>Status</th>
          <th>Action</th>
        </thead>
        <tbody>
          <tr>
            <td><b-form-input v-model="s_id"/></td>  
            <td><b-form-input v-model="s_name"/></td>  
            <td><b-form-input/></td>
            <td><b-form-select v-model="selected" :options="options" class="mb-3" /></td>
            <td><b-button variant="warning" :active="true">Search</b-button></td>   
          </tr>
        <template v-for="items in rowDatas">
          <tr>  
            <td>{{items.id}}</td>  
            <td>{{items.name}}</td>  
            <td>{{items.discription}}</td>
            <template v-if="items.status">
              <td><c-switch type="text" variant="success" on="On" off="Off" :pill="true" :checked="true" size="lg"/></td>   
            </template>
            <template v-else>
              <td><c-switch type="text" variant="success" on="On" off="Off" :pill="true" :checked="false" size="lg"/></td> 
            </template>
            
            <td>
              <b-btn size="sm" variant="primary" @click="editData(items)">Edit</b-btn>
              <b-btn size="sm" variant="danger">Delete</b-btn>
            </td>
          </tr>  
        </template>
        </tbody>
      </table>
      <uib-pagination :total-items="totalRows" :items-per-page="20" v-model="pagination" class="pagination-sm" @change="pageChanged(pagination.currentPage)"></uib-pagination>
    </b-card>  
    <b-modal ref="addNewModal" title="New Category">
      <div class="d-block">
        <table class="table table-striped">
          <tr>
            <td>Name</td>
            <td><b-form-input v-model="newName"/></td>
          </tr>
          <tr>
            <td>Status</td>
            <td><c-switch v-model="newStatus" type="text" variant="success" on="On" off="Off" :pill="true" :checked="true" size="lg"/> {{newStatus}}</td>  
          </tr>
          <tr>
            <td>Discription</td>
            <td><b-form-input :textarea="true" :rows="5" v-model="newDisciption"></b-form-input></td>
            </tr>
        </table>
      </div>
    </b-modal>
    <b-modal ref="editModal" title="New Category">
      <div class="d-block">
        <table class="table table-striped">
          <tr>
            <td>Name</td>
            <td><b-form-input v-model="ModalData.name"/></td>
          </tr>
          <tr>
            <td>Status</td>
            <td><c-switch v-model="ModalData.status" type="text" variant="success" on="On" off="Off" :pill="true" :checked="true" size="lg"/></td>  
          </tr>
          <tr>
            <td>Discription</td>
            <td><b-form-input v-model="ModalData.discription"></b-form-input></td>
            </tr>
        </table>
      </div>
    </b-modal>
-->
  </div>
</template>

<script>
import cSwitch from '../../components/Switch'
export default {
  name: 'Categories',
  components: {
    cSwitch
  },
  computed: {
    icon (icon) {
      return icon
    }
  },
  data () {
    return {
      totalRows: 1,
      pagination: {},
      s_id: '',
      s_name: '',
      s_status: true,
      selected: '',
      newName: '',
      newDisciption: '',
      newStatus: true,
      ModalData: {},
      options: [
        {value: null, text: 'All'},
        {value: 'on', text: 'On'},
        {value: 'off', text: 'Off'}
      ],
      rowDatas: [
        {id: '1', name: 'category 1', discription: 'product category 1', status: true},
        {id: '2', name: 'category 2', discription: 'product category 2', status: true},
        {id: '3', name: 'category 3', discription: 'product category 3', status: false},
        {id: '4', name: 'category 4', discription: 'product category 4', status: true},
        {id: '5', name: 'category 5', discription: 'product category 5', status: true}
      ]
    }
  },
  methods: {
    addNew () {
      this.$refs.addNewModal.show()
    },
    editData (data) {
      this.ModalData = data
      this.$refs.editModal.show()
    }
  }
}
</script>
